.section3{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
}
.section3-container{
    width: 100%;
    max-width: 1300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;

}
.section3-file1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.section3-file1 img{
    width: 50%;
}
.section3-file1 div{
    width: 42%;

}
.section3-file1 h3{
    color: #807373;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.section3-file1 h2{
    font-family: Inter;
    text-align: start;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
}
.section3-file1 p{
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 85%;
}
.section3 button{
    background-color: #FFE20D;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 250px;
    border: none;
    border-radius: 18px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

}

.section3 button:hover{
    background-color: #766907;
    box-shadow: none;
}
.section3 button > p{
    font-size: 16px;
}
.section3 button > img{
    width: 30px;
    margin: 0 0 0 20px;
}

.section3-file2{
    margin-top: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
}
.section3-file2 div{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section3-file2 img{
    width: 62px;
}
.section3-file2 h2{
    text-align: center;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.section3-file2 p{
    text-align: center;
    font-size: 16px;
    width: 80%;
}


@media (max-width: 1350px) {
    .section3-container h2{
        font-size: 30px;
    }
    .section3-container h3{
        font-size: 16px;
    }
    .section3-file1 p{
        font-size: 15px;

    }
}

@media (max-width: 800px) {
    .section3-container{
        padding: 0;

    }
    .section3-file1{
        flex-direction: column-reverse;
    }
    .section3-file1 div{
        width: 100%;
        margin-bottom: 50px;
        padding: 0 20px;

    }
    .section3-file1 img{
        width: 100%;
    }
    .section3-file2{
        flex-direction: column;
    }
    .section3-file2 div{
        width: 100%;
        margin-bottom: 50px;
    }

}