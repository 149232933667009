.section6{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(217, 217, 217, 0.35);
    padding: 20px 0;
}
.section6-container{
    width: 100%;
    height: 100%;
    max-width: 1300px;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
}
.section6-container p{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 49%;
    margin: 0;
}

@media (max-width: 1350px) {
   
    .section6-container{
        padding: 0 50px;
        align-items: center;
    }
}

@media (max-width: 650px) {
    .section6-container{
        flex-direction: column;
        padding: 0 20px;
    }
    .section6-container p{
        width: 100%;
        margin-bottom: 20px;
    }
}