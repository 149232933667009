.footer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #010035;    
    padding: 40px 0;
}
.footer-container{
    width: 100%;
    height: 100%;
    max-width: 1300px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.footer-logo{
    width: 300px;
}
.footer-section2{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
}
.footer-section2 img{
    width: 50%;
    margin-bottom: 30px;
}

.footer-section2 button{
    background-color: #FFE20D;
    border: none;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    padding: 10px 30px ;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    cursor: pointer;
}
.footer-section2 button:hover{
    background-color: #766907;
    box-shadow: none;
}

.footer-section3 div{
    display: flex;
    flex-direction: row;
    justify-content: start;
}
.footer-section3 img{
    width: 30px;
    margin-right: 20px;
}
.footer-phone{
    width: 48px !important;
    margin-left: -14px;

}
.footer-section3 a{
    color: #FFE20D;
    margin-left: 50px;
}
.footer-section3 p{
    color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

@media (max-width: 1350px) {
   
    .footer-container{
        padding: 0 50px;
        align-items: center;
    }
}

@media (max-width: 1000px) {
    .footer-logo{
        width: 20%;
    }
    .footer-section3 p{
        font-size: 14px;
    }
    .footer-section3 img{
        width: 30px;
    }
}

@media (max-width: 650px) {
    .footer-container{
        flex-direction: column;
    }
    .footer-logo{
        width: 70%;
        margin-bottom: 50px;
    }
    .footer-section2 img{
        display: none;
    }
    .footer-section2 {
        width: 100%;
        margin-bottom: 50px;
    }
   
    
}