.section2{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -120px;
}
.section2-container{
    background-color: #010035;
    width: 100%;
    max-width: 1300px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 34px;
    padding: 20px;

}
.section2-separate{
    width: 10px;
    background-color: #D9D9D9;
    height: 180px;
    margin: 0 50px;
    border-radius: 50px;
}
.section2 img{
    width: 300px;
}
.section2 p{
    color: white;
    font-size: 20px;
    padding-right: 50px;
    line-height: 30px;
}


@media (max-width: 1350px) {
    .section2-container{
        margin: 0 50px;
    }
}
@media (max-width: 800px) {
    .section2-container{
        margin: 0 0px;
        border-radius: 0;
    }
}
@media (max-width: 800px) {
    .section2-container{
        flex-direction: column;
        text-align: center;
        padding: 50px 0;
    }
    .section2-separate{
        display: none;
    }
    .section2 p{
        padding: 0 10px;
        font-size: 16px;
    }
}