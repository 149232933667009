@import url('./globals.css') all;
@import url('./nav.css') all;
@import url('./section1.css') all;
@import url('./section2.css') all;
@import url('./section3.css') all;
@import url('./section4.css') all;
@import url('./section5.css') all;
@import url('./section6.css') all;
@import url('./footer.css') all;
body{
    margin: 0;
    font-family: 'Inter', sans-serif;
}
.menu a{
    color: #000; 
}
.menu a:visited{
    color: #000;
}
.menu > li:hover{
      color: gray;
}
a{
    text-decoration: none;
}
button > a {
    text-decoration: none;

}
@media (max-width: 1100px) {
    .menu a:visited{
        color: white;
    }
    .menu > li:last-child {
        border-radius: 0;
    }
    .menu > li:last-child > a {
        color: black;

    }
    
}