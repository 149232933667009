.section4{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255, 226, 13, 0.10);
    padding: 10px 0 70px;
    margin-top: 100px;
}
.section4 h2{
    text-align: center;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
}
.section4-container{
    width: 100%;
    height: 100%;
    max-width: 1300px;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
}

.section4-container div{
    display: flex;;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 23%;
}

.section4-container h3{
    font-weight: bold;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
}
.section4-container p{
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
}

@media (max-width: 1350px) {
    .section4-container{
        padding: 0 50px;
    }
}

@media (max-width: 850px) {
    .section4-container div{
        width: 30%;
    }
    .section4-container p{
        font-size: 16px;
    }

}
@media (max-width: 650px) {
    .section4-container {
        flex-direction: column;
        align-items: center;
    }
    .section4-container div{
        width: 100%;
        margin-bottom: 50px;
    }
}