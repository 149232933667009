.section5{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 150px 0;
    
}
.section5-container{
    width: 100%;
    height: 100%;
    max-width: 1300px;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
}
.section5-container img{
    width: 43%;
}
.section5-container div{
    width: 50%;
 
}
.section5-container h2{
    text-align: start;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
}
.section5-container p{
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 70px;
}

.section5 button{
    background-color: #FFE20D;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 300px;
    border: none;
    border-radius: 18px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

}
.section5 button:hover{
    background-color: #766907;
    box-shadow: none;
}
.section5 button > p{
    font-size: 20px;
    margin-bottom: 0;
}
.section5 button > img{
    width: 30px;
    margin-left: 10px;
}

@media (max-width: 1350px) {
   
    .section5-container{
        padding: 0 50px;
        align-items: center;
    }
}

@media (max-width: 850px) {
    .section5-container p{
        font-size: 14px;
    }
}

@media (max-width: 650px) {
    .section5-container {
        flex-direction: column;
        padding: 0;
    }
    .section5-container div{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        margin-bottom: 50px;
        text-align: center;
    }
    .section5-container h2{
        text-align: center;
    }
    .section5-container img{
        width: 100%;
       
    }
}
