.section1{
    width: 100%;
    height: 900px;
    background-image: url('../source//bg.png');
    background-repeat: none;
    background-size: cover;
    display: flex;
    justify-content: center;
}

.section1-container{
    width: 100%;
    height: 100%;
    max-width: 1300px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.section1 h1{
    color: #FFF;
    font-family: Inter;
    font-size: 80px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin: 0 0 0 0;
}
.section1 h3{
    margin: 20px 0 0 0;
    color: #FFF;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    
}
.section1-qr{
    width: 216px;
    margin-bottom: 20px;
}
.section1 button{
    background-color: #FFE20D;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: none;
    border-radius: 18px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

}
.section1 button:hover{
    background-color: #766907;
    box-shadow: none;
}
.section1 button > img{
    width: 30px;
    margin-left: 10px;
}

@media (max-width: 1350px) {
    .section1-container{
        padding: 0 50px;
    }
}
@media (max-width: 650px) {
    .section1-container{
        flex-direction: column;
        justify-content: space-evenly;
        text-align: center;
    }
    .section1-container button{
        width: 300px;
    }
    .section1-container h1{
        font-size: 50px;
    }
    .section1-container h3{
        font-size: 25px;

    }
    .section1-qr{
        display: none;
    }
}